import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  GetOrderDocument,
  GetOrderQuery,
  GetOrderQueryVariables,
  useGetNursesQuery,
  useUpdateOrderCollectionNurseMutation,
} from '../generated/urql.administrator'
import { Controller, useForm } from 'react-hook-form'
import { useClient } from 'urql'

export type OrderNurseModalType = {
  show: (args: { orderId: number }) => void
}

type FormOrderNurse = {
  nurseId: number
}

const OrderNurseModal = forwardRef<
  OrderNurseModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [orderId, setOrderId] = useState<number>()
  const client = useClient()
  const [
    { fetching: updateOrderCollectionNurseFetching },
    updateOrderCollectionNurse,
  ] = useUpdateOrderCollectionNurseMutation()
  const [{ data: nursesData, fetching: nursesFetching }] = useGetNursesQuery({
    variables: { limit: 0xfffff },
  })
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm<FormOrderNurse>({
    defaultValues: {
      nurseId: undefined,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ orderId }) => {
      setOrderId(orderId)
      setShow(true)
    },
  }))

  useEffect(() => {
    if (orderId) {
      client
        .query<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, {
          id: orderId,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.order_by_pk) {
            reset({
              nurseId: data.order_by_pk.orderCollections[0]?.nurse?.id,
            })
          }
        })
    }
  }, [client, orderId, reset])

  async function doSaveOrderNurse(form: FormOrderNurse) {
    setGeneralError(undefined)

    if (!orderId) {
      setGeneralError('Missing orderId')
      return
    }

    const { error } = await updateOrderCollectionNurse({
      ...form,
      orderId,
    })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setOrderId(undefined)
    onChange()
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setOrderId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveOrderNurse)}>
        <Modal.Header closeButton>
          <Modal.Title>Osoba pobierająca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            name="nurseId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FloatingLabel label="Osoba pobierająca" className="mb-3">
                <Form.Select
                  disabled={!nursesData?.nurse.length || nursesFetching}
                  value={String(value)}
                  onChange={onChange}
                >
                  <option>Proszę wybrać osobę pobierającą</option>
                  {nursesData?.nurse.map((nurse) => (
                    <option value={nurse.id} key={nurse.id}>
                      {nurse.name} (#{nurse.id})
                    </option>
                  ))}
                </Form.Select>
                {errors.nurseId && (
                  <Form.Control.Feedback>
                    {errors.nurseId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={updateOrderCollectionNurseFetching}
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default OrderNurseModal
