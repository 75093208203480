import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetOrdersDocument,
  GetOrdersQuery,
  GetOrdersQueryVariables,
  Order_By,
  Order_State_Enum_Enum,
} from '../generated/urql.administrator'
import { useClient } from 'urql'
import { Alert, Badge, Button, ButtonGroup, Spinner } from 'react-bootstrap'
import {
  CalendarDate,
  Clock,
  PersonPlus,
  Receipt,
  ZoomIn,
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'
import { getOrderStateLabel, OrderState2BgVariant } from '../lib/order'
import moment from 'moment'

const BatchSize = 20

const Orders: FC = () => {
  // page content
  const pageTitle = 'Zamówienia'
  const [orders, setOrders] = useState<GetOrdersQuery['order']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const navigate = useNavigate()
  const data = useMemo(() => orders, [orders])
  const [generalError, setGeneralError] = useState<string>()
  const [loading, setLoading] = useState(false)

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      setLoading(true)

      try {
        const { data, error } = await client
          .query<GetOrdersQuery, GetOrdersQueryVariables>(
            GetOrdersDocument,
            {
              offset: refetch ? 0 : offset,
              limit: BatchSize,
              orderBy: { id: Order_By.Desc },
            },
            { requestPolicy: refetch ? 'network-only' : undefined }
          )
          .toPromise()

        if (error) {
          setGeneralError(error.message)
          return
        }

        if (data) {
          setOrders(refetch ? data.order : orders.concat(data.order))
          setOffset(refetch ? BatchSize : offset + BatchSize)
          setCount(data.order_aggregate.aggregate?.count || 0)
        }
      } finally {
        setLoading(false)
      }
    },
    [client, orders, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/order/bulk')}
          >
            <Receipt size={20} />
            &nbsp;Dodawanie zbiorcze
          </Button>
        </ButtonGroup>
      </div>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <InfiniteTable<GetOrdersQuery['order'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          {
            Header: 'Data',
            accessor: ({ firstStateDate }) => (
              <>
                <span className="d-flex align-items-center">
                  <CalendarDate size={12} className="me-1" />
                  <span>{moment(firstStateDate).format('yyyy-MM-DD')}</span>
                </span>
                <span className="d-flex align-items-center">
                  <Clock size={12} className="me-1" />
                  <span>{moment(firstStateDate).format('HH:mm:ss')}</span>
                </span>
              </>
            ),
          },
          {
            Header: 'Profile',
            id: 'client',
            accessor: (rowData) => {
              const orderProfiles = [
                ...rowData.orderTests,
                ...rowData.orderConsultations,
                ...(rowData.orderTrainings.length > 0
                  ? rowData.client.clientProfiles
                  : []),
              ].filter(function (item1, pos, self) {
                return (
                  self.findIndex(
                    (item2) =>
                      item1.lastName === item2.lastName &&
                      item1.firstName === item2.firstName
                  ) === pos
                )
              })
              return (
                <div>
                  {orderProfiles.map((orderItem) => (
                    <div className="fw-bold">
                      {orderItem.firstName} {orderItem.lastName}
                    </div>
                  ))}
                  {!!rowData.orderCollections[0] && (
                    <div>
                      {rowData.orderCollections[0].city}{' '}
                      {rowData.orderCollections[0].postalCode}
                    </div>
                  )}
                </div>
              )
            },
          },
          {
            Header: 'Kurier',
            id: 'courier',
            accessor: (rowData) => (
              <>
                {rowData.orderCollections[0]?.nurse &&
                  !rowData.orderCollections[0].nurse.deletedAt && (
                    <div>
                      <a
                        href={`/nurse/${rowData.orderCollections[0].nurse.id}`}
                      >
                        {rowData.orderCollections[0].nurse.name}
                      </a>
                    </div>
                  )}
                {rowData.orderCollections[0]?.nurse &&
                  rowData.orderCollections[0].nurse.deletedAt && (
                    <del>{rowData.orderCollections[0].nurse.name}</del>
                  )}
                {!rowData.orderCollections[0]?.nurse && <span>-</span>}
              </>
            ),
          },
          {
            Header: 'Laboratorium',
            id: 'laboratories',
            accessor: ({ orderTestLaboratories }) => (
              <>
                {orderTestLaboratories.map(({ collectionPoint }) => (
                  <>
                    {collectionPoint && (
                      <div key={collectionPoint.id}>
                        <a
                          href={`/laboratory/${collectionPoint.laboratory.id}`}
                        >
                          {collectionPoint.laboratory.shortName ||
                            collectionPoint.laboratory.name}{' '}
                          / {collectionPoint.name}
                        </a>
                      </div>
                    )}
                  </>
                ))}
              </>
            ),
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: (rowData) => (
              <Badge
                bg={
                  OrderState2BgVariant[
                    rowData.currentState as Order_State_Enum_Enum
                  ]
                }
              >
                {getOrderStateLabel(rowData)}
              </Badge>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/order/${rowData.id}`)}
                  >
                    <ZoomIn size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      {loading && (
        <div>
          <Spinner animation="border" />
        </div>
      )}
    </div>
  )
}

export default Orders
